<template>
  <v-container class="fill-height px-0" fluid>
    <v-row align="start" justify="center" class="fill-height">
      <v-card-text class="mt-n4 mb-n4">
        <v-progress-linear
          :active="loading || overlay"
          indeterminate
          color="primary"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
      <v-col md="12" sm="12">
        <v-card class="pa-md-4 pa-sm-2">
          <v-card-subtitle
            v-if="!isMobile"
            class="
            d-flex justify-center mt-n4 mx-n8 font-weight-bold
          "
          >
            <v-col cols="2">Réf : </v-col>
            <v-col cols="3">{{ formatID(ticketId) }}</v-col>
            <v-col cols="1">
              <v-btn
                v-if="!isDirectLink && mailLink"
                class="ml-6 mt-n4"
                small
                dark
                color="white"
                :href="mailLink"
                target="_blank"
                title="Envoyer par mail"
              >
                <v-icon color="primary">mdi-email</v-icon>
              </v-btn>
            </v-col>
          </v-card-subtitle>
          <ticket-action
            :ticket-id="ticketId"
            :entity-id="entityId"
            :ticket-status="ticket.status"
            @newComment="addNewITILFollowup"
            @newSolution="addNewITILSolution"
            @newTask="addNewTask"
          ></ticket-action>
          <v-col v-if="isGest && isEncours">
            <div v-if="isMobile" class="mt-n9">
              <v-row>
                <v-col cols="3" offset="0">
                  <v-btn
                    v-if="!isDirectLink && mailLink"
                    class="ml-3"
                    small
                    dark
                    color="white"
                    :href="mailLink"
                    target="_blank"
                    title="Envoyer par mail"
                  >
                    <v-icon color="primary">mdi-email</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="3" offset="6">
                  <v-btn
                    class="mt-n12 mb-n3"
                    :small="$vuetify.breakpoint.smAndDown"
                    :large="$vuetify.breakpoint.mdAndUp"
                    dark
                    :fab="$vuetify.breakpoint.smAndDown"
                    @click="updateTicketStatus"
                    color="orange"
                    target="_blank"
                    title="Changer le statut à en 'En cours'"
                  >
                    <v-icon color="white">mdi-reload</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-else class="mx-2 d-flex justify-start align-items-center">
              <v-row>
                <v-col cols="4" offset="6">
                  <v-btn
                    class="mt-n2 ml-1"
                    small
                    dark
                    @click="updateTicketStatus"
                    color="orange"
                    target="_blank"
                    title="Changer le statut à en 'En cours'"
                  >
                    Mettre à en cours
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <time-line
            v-if="list.length > 0"
            :list="list"
            :selected-ticket="{
              name: ticketName,
              date_creation: ticketDate,
              content: ticket.content
            }"
          ></time-line>
          <v-skeleton-loader
            ref="skeleton"
            type="list-item-avatar-three-line"
            class="mx-auto"
            v-if="loading"
          ></v-skeleton-loader>
          <div class="d-flex flex-column" v-if="loaded && list.length === 0">
            <v-timeline
              :dense="$vuetify.breakpoint.smAndDown"
              class="timeline__wrapper"
            >
              <v-timeline-item left color="warning" icon class="text-right">
                <template slot="icon">
                  <v-icon x-large class="white--text"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span slot="opposite">
                  <v-chip>
                    <v-icon color="success">access_time </v-icon>
                    {{
                      moment(ticket.date_creation).format("DD/MM/YYYY  HH:mm")
                    }}
                  </v-chip>
                </span>
                <div style="width: 100%" class="mt-n8">
                  <div class="ma-2 text-justify incident pb-2" outlined x-large>
                    <div class="subtitle-1">
                      Déclaration d'intervention:
                    </div>
                    <hr />
                    <div
                      class="subtitle-2 pt-2 pb-2"
                      v-if="ticket.content"
                      v-html="decodeContent(ticket.content)"
                    ></div>
                    <hr />
                  </div>
                </div>
              </v-timeline-item>
              <v-timeline-item right color="grey" icon>
                <template slot="icon">
                  <v-icon x-large class="white--text"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <v-chip class="ma-2" color="grey" outlined>
                  Aucun élement de suivi
                </v-chip>
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "../../plugins/moment";
import { Decoder } from "../../mixins/decodeContent";
import TimeLine from "../TimeLine";
import TicketAction from "../TicketAction";
import { setupTicketsComponent } from "../../composition/setupTicketsData";
import { formatID } from "@/utils";
import { mapGetters } from "vuex";
import { computed } from "@vue/composition-api";
import { PORTAL_CONFIG } from "../../config";
import { gestProfile } from "../../utils";

export default {
  name: "TicketDetail",
  components: { TimeLine, TicketAction },
  props: {
    ticketId: {
      type: Number,
      required: true
    },
    ticketName: {
      type: String,
      default: ""
    },
    ticketDate: {
      type: String,
      default: ""
    },
    overlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      titleText: ""
    };
  },
  setup(props, context) {
    if (context.root) {
      const ticketId = props.ticketId;
      const tasks = computed(() =>
        context.root.$store.getters.getTicketsTasks.filter(
          task => task.tickets_id === ticketId
        )
      );
      const followUps = computed(() =>
        context.root.$store.getters.getAllITILFollowups.filter(
          task => task.items_id === ticketId
        )
      );
      const itSolutions = computed(() =>
        context.root.$store.getters.getAllITILSolutions.filter(
          task => task.items_id === ticketId
        )
      );
      return {
        ...setupTicketsComponent(ticketId, tasks, followUps, itSolutions),
        formatID,
        tasks,
        followUps,
        itSolutions
      };
    }
  },
  mixins: [Decoder],
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getActiveEntity",
      "getTicketsTasks",
      "getEntity",
      "getUserProfiles"
    ]),
    entityId() {
      return this.getActiveEntity.id ? this.getActiveEntity.id : NaN;
    },
    isDirectLink() {
      return this.$route.meta.directLink;
    },
    /*redirectLink() {
      //s'il y a un token visiteur sur l'entité de niveau 5
      if (isNaN(this.entityId)) {
        return false;
      } else {
        if (this.getActiveEntity.fax) {
          let visitToken = JSON.parse(this.getActiveEntity.fax).user[0];
          return `${PORTAL_CONFIG.WEB_URL}/view/ticket/${this.ticketId}/u/${visitToken}`;
        } else {
          //S'il y a un token visiteur sur l'entité de niveau 4
          let parentEntity = this.getEntity.filter(
            entity =>
              entity.level === 4 &&
              entity.id === this.getActiveEntity.entities_id
          );
          if (parentEntity[0].fax) {
            let visitToken = JSON.parse(parentEntity[0].fax).user[0];
            return `${PORTAL_CONFIG.WEB_URL}/view/ticket/${this.ticketId}/u/${visitToken}`;
          } else {
            //S'il y a un token visiteur sur l'entité de niveau 3
            let grandparentEntity = this.getEntity.filter(
              entity =>
                entity.level === 3 && entity.id === parentEntity[0].entities_id
            );
            if (grandparentEntity[0].fax) {
              let visitToken = JSON.parse(grandparentEntity[0].fax).user[0];
              return `${PORTAL_CONFIG.WEB_URL}/view/ticket/${this.ticketId}/u/${visitToken}`;
            }
          }
        }
      }
      return false;

      //return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },*/
    mailLink() {
      //s'il y a un token visiteur sur l'entité de niveau 5
      if (isNaN(this.entityId)) {
        return false;
      } else {
        if (this.getActiveEntity.fax) {
          let visitToken = JSON.parse(this.getActiveEntity.fax).user[0];
          let urlTicketDetail =
            PORTAL_CONFIG.WEB_URL +
            "/view/ticket/" +
            this.ticketId +
            "/u/" +
            visitToken;
          return `mailto:?subject=Suivi intervention ${this.ticketId}&body=${urlTicketDetail}`;
        } else {
          //S'il y a un token visiteur sur l'entité de niveau 4
          let parentEntity = this.getEntity.filter(
            entity =>
              entity.level === 4 &&
              entity.id === this.getActiveEntity.entities_id
          );
          if (parentEntity[0].fax) {
            let visitToken = JSON.parse(parentEntity[0].fax).user[0];
            let urlTicketDetail =
              PORTAL_CONFIG.WEB_URL +
              "/view/ticket/" +
              this.ticketId +
              "/u/" +
              visitToken;
            return `mailto:?subject=Suivi intervention ${this.ticketId}&body=${urlTicketDetail}`;
          } else {
            //S'il y a un token visiteur sur l'entité de niveau 3
            let grandparentEntity = this.getEntity.filter(
              entity =>
                entity.level === 3 && entity.id === parentEntity[0].entities_id
            );
            if (grandparentEntity[0].fax) {
              let visitToken = JSON.parse(grandparentEntity[0].fax).user[0];
              let urlTicketDetail =
                PORTAL_CONFIG.WEB_URL +
                "/view/ticket/" +
                this.ticketId +
                "/u/" +
                visitToken;
              return `mailto:?subject=Suivi intervention ${this.ticketId}&body=${urlTicketDetail}`;
            }
          }
        }
      }
      return false;
    },
    isGest() {
      return this.getUserProfiles.includes(gestProfile);
    },
    isEncours() {
      return this.ticket.status < 4;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    moment,
    updateTicketStatus() {
      this.$emit("updateTicketStatusToPending");
    }
  }
};
</script>

<style scoped></style>
