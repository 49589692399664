<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card class="elevation-3">
    <v-col lg="12" class="px-0 px-md-3">
      <v-card v-if="isMobile" flat>
        <v-row no-gutters class="pa-2">
          <v-col cols="9">
            <v-card-title class="headline display-1">
              Interventions
            </v-card-title>
          </v-col>
          <v-col cols="3" md="6" class="mt-3 pr-2">
            <v-row justify="center">
              <v-dialog v-model="docFilterDialog">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Filtres
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Filtres</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            label="par statut"
                            :items="status"
                            clearable
                            v-model="selectedStatus"
                            multiple
                            chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            :label="$t('tableStatus.by_category')"
                            :items="categories"
                            clearable
                            v-model="selectedCategory"
                            multiple
                            chips
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="docFilterDialog = false"
                      >OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>

          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            single-line
            hide-details
            class="mt-n2"
          ></v-text-field>
        </v-row>
      </v-card>

      <v-card flat v-else>
        <v-card-title class="headline display-1">
          {{ $t("header.list_reported") }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            clearable
            single-line
            hide-details
            :class="classMod"
          >
          </v-text-field>
        </v-card-title>
        <v-card-text class="elevation-2 grey mt-n2 lighten-5 pt-2">
          <v-row>
            <v-col md="4" sm="12" class="mt-n3 mb-n7">
              <v-card-title>
                <div class="mr-3">
                  <toggle-button
                    :value="true"
                    color="var(--v-primary-base)"
                    @change="deGroup()"
                  />
                </div>
                Grouper par logements</v-card-title
              >
            </v-col>
            <v-col md="5" sm="12" class="mt-n3 mb-n7">
              <v-select
                label="Filtrer par statut"
                :items="status"
                clearable
                v-model="selectedStatus"
                multiple
                chips
              ></v-select>
            </v-col>
            <v-col md="3" sm="12" class="mt-n3 mb-n7">
              <v-select
                :label="$t('tableStatus.by_category')"
                :items="categories"
                clearable
                multiple
                chips
                v-model="selectedCategory"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-subtitle v-if="!this.searched">
        {{
          filteredItems.length > 1
            ? filteredItems.length + " dégradations (filtrées) "
            : filteredItems.length + " dégradation (filtrée) "
        }}
        /
        {{
          selectedDegradations.length > 1
            ? selectedDegradations.length + " dégradations sélectionnées"
            : selectedDegradations.length + " dégradation sélectionnée"
        }}
      </v-card-subtitle>
      <v-card-subtitle v-else>
        {{
          selectedDegradations.length > 1
            ? selectedDegradations.length + " dégradations sélectionnées"
            : selectedDegradations.length + " dégradation sélectionnée"
        }}
      </v-card-subtitle>
      <template>
        <v-card-text class="px-2 px-md-4" :id="tableId">
          <v-data-table
            v-model="selectedDegradations"
            :headers="headers"
            :items="filteredItems"
            item-key="id"
            :key="keyTab"
            :loading="loading"
            @click:row="clicked"
            class="table is_bordered"
            sort-by="date"
            sort-desc
            :group-by="groupement"
            :mobile-breakpoint="this.$vuetify.breakpoint.thresholds.md"
            :group-desc="false"
            :search="searched"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
            show-select
          >
            <template v-slot:header.data-table-select="{ props, on }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-avatar class="white ml-3 " size="32">
                      <v-icon color="primary">menu</v-icon>
                    </v-avatar>
                  </v-btn>
                </template>
                <v-list class="pa-0" light>
                  <v-list-item
                    style="background-color: rgb(240, 240, 240);"
                    @click="unselectAll()"
                    :disabled="selectedDegradations.length === 0"
                  >
                    Effacer sélection
                  </v-list-item>
                  <v-list-item
                    @click="privateIncident(selectedDegradations, 2)"
                    :disabled="selectedDegradations.length === 0"
                  >
                    Rendre privé
                  </v-list-item>
                  <v-list-item
                    style="background-color: rgb(240, 240, 240);"
                    @click="privateIncident(selectedDegradations, 1)"
                    :disabled="selectedDegradations.length === 0"
                  >
                    Rendre public
                  </v-list-item>
                  <v-list-item
                    @click="encoursIncident(selectedDegradations)"
                    :disabled="selectedDegradations.length === 0"
                  >
                    Passer à en cours
                  </v-list-item>
                  <v-list-item
                    style="background-color: rgb(240, 240, 240);"
                    @click="permanentIncident(selectedDegradations)"
                    :disabled="selectedDegradations.length === 0"
                  >
                    Passer à permanent
                  </v-list-item>
                  <v-list-item
                    @click="cloreIncident(selectedDegradations)"
                    :disabled="selectedDegradations.length === 0"
                  >
                    Clore
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:group.header="{ toggle, group, isOpen }">
              <td colspan="12" class="pl-0">
                <v-btn @click="toggle()" icon>
                  <v-icon
                    >mdi-{{
                      isOpen
                        ? "arrow-up-drop-circle-outline"
                        : "arrow-down-drop-circle-outline"
                    }}</v-icon
                  >
                </v-btn>
                <span class="primary--text">{{ group }}</span>
              </td>
            </template>

            <template v-slot:item="{ item, isMobile }">
              <mobile-row
                :item="item"
                :headers="mobileHeaders"
                v-if="isMobile"
                @expand="expandRow"
                :is-expanded="shoulExpand(item.id)"
                @n-expand="removeFromExpanded"
                @row-clicked="clicked"
              >
                <template v-slot:id="{ item }">
                  {{ formatID(item.id) }}
                </template>
                <template v-slot:name="{ item }">
                  <v-col>
                    <div class="ml-n3">
                      {{ item.name }}
                    </div>
                  </v-col>
                  <v-col>
                    <div align="end">
                      <v-edit-dialog
                        :return-value.sync="item.name"
                        large
                        @save="saveName(item, item.name)"
                      >
                        <div>
                          <v-icon x-small>mdi-pencil</v-icon>
                        </div>
                        <template v-slot:input>
                          <div class="mt-4 text-h6">MAJ nom</div>
                          <v-text-field
                            v-model="item.name"
                            label="Edit"
                            single-line
                            counter
                            autofocus
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </div>
                  </v-col>
                </template>
                <template v-slot:statusName="{ item }">
                  <v-chip
                    label
                    small
                    :color="getStatus(item.status).color"
                    text-color="white"
                  >
                    {{ getStatus(item.status).name }}
                  </v-chip>
                </template>
                <template v-slot:content="{ item }">
                  <v-col>
                    <div
                      class="ml-n3"
                      :class="{ 'test-class': isMobile }"
                      v-if="item.content"
                      v-html="decodeContent(item.content)"
                    ></div>
                  </v-col>
                  <v-col>
                    <div align="end">
                      <v-edit-dialog
                        :return-value.sync="item.content"
                        large
                        @save="saveContent(item, item.content)"
                      >
                        <div>
                          <v-icon x-small>mdi-pencil</v-icon>
                        </div>
                        <template v-slot:input>
                          <div class="mt-4 text-h6">MAJ description</div>
                          <tiptap-vuetify
                            :value="decodeContent(item.content)"
                            @input="item.content = $event"
                            :extensions="extensions"
                          />
                        </template>
                      </v-edit-dialog>
                    </div>
                  </v-col>
                </template>
                <template v-slot:last_comment="{ item }">
                  <div
                    v-if="item.last_comment"
                    class="incident comments"
                    v-html="decodeContent(item.last_comment)"
                  ></div>
                </template>
                <template v-slot:itilcategories_id="{ item }">
                  {{
                    item.itilcategories_id === 0
                      ? "Non Défini"
                      : item.itilcategories_id
                  }}
                </template>
                <template v-slot:emailfield="{ item }">
                  {{ item.contact_mail }}
                </template>
                <template v-slot:date="{ item }">
                  {{
                    item.date
                      ? moment(item.date).format("DD-MM-YYYY HH:mm")
                      : ""
                  }}
                </template>
                <template v-slot:solvedate="{ item }">
                  {{
                    item.solvedate
                      ? moment(item.solvedate).format("DD-MM-YYYY HH:mm")
                      : ""
                  }}
                </template>
              </mobile-row>
              <tr v-else :item="item" :headers="headers" rtl>
                <td>
                  <v-checkbox
                    :key="item.id"
                    v-model="selectedDegradations"
                    :value="item.id"
                  ></v-checkbox>
                </td>
                <td
                  @click="clicked(item)"
                  v-if="item.equipment && slider == true"
                  class="d-none"
                >
                  <div class="incident equipment">
                    {{ hideElt(item.equipment) }}
                  </div>
                </td>
                <td @click="clicked(item)" v-else-if="item.equipment">
                  <div class="incident">
                    {{ item.equipment }}
                  </div>
                </td>
                <td @click="clicked(item)" v-html="formatText(item)"></td>
                <td @click="clicked(item)" class="pl-1">
                  {{ item.name }}
                  <div class="ml-n1">
                    <v-edit-dialog
                      :return-value.sync="item.name"
                      large
                      @save="saveName(item, item.name)"
                    >
                      <div>
                        <v-icon>mdi-playlist-edit</v-icon>
                      </div>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">MAJ nom</div>
                        <v-text-field
                          v-model="item.name"
                          label="Edit"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </td>

                <td @click="clicked(item)">
                  {{ item.date ? formatDate(item.date) : "" }}
                </td>

                <td @click="clicked(item)">
                  <v-chip
                    label
                    small
                    :color="getStatus(item.status).color"
                    text-color="white"
                  >
                    {{ getStatus(item.status).name }}
                  </v-chip>
                </td>
                <td @click="clicked(item)">
                  <div
                    class="incident description"
                    :class="{ 'test-class': isMobile }"
                    v-if="item.content"
                    v-html="decodeContent(item.content)"
                  ></div>
                  <v-edit-dialog
                    class="ml-n1"
                    :return-value.sync="item.content"
                    large
                    @save="saveContent(item, item.content)"
                  >
                    <div>
                      <v-icon class="ml-n1">mdi-playlist-edit</v-icon>
                    </div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">MAJ description</div>
                      <tiptap-vuetify
                        :value="decodeContent(item.content)"
                        @input="item.content = $event"
                        :extensions="extensions"
                      />
                    </template>
                  </v-edit-dialog>
                </td>
                <td @click="popup()">
                  <div>
                    <v-chip
                      :color="
                        item.itilcategories_id === 'Exploitation'
                          ? 'primary'
                          : 'secondary'
                      "
                      text-color="white"
                    >
                      {{
                        item.itilcategories_id === 0
                          ? "Non Défini"
                          : item.itilcategories_id
                      }}</v-chip
                    >
                  </div>
                </td>
                <td @click="popup()" align="center">
                  <div>
                    <v-chip
                      label
                      small
                      :color="item.type == 2 ? 'grey' : 'secondary'"
                      text-color="white"
                    >
                      {{ item.type == 1 ? "PUBLIC" : "PRIVÉ" }}</v-chip
                    >
                  </div>
                </td>

                <td @click="clicked(item)">
                  {{ item.contact_mail ? item.contact_mail : "masqué" }} <br />
                  {{
                    item.contact_tel
                      ? /^[a-zA-Z]+$/.test(item.contact_tel)
                        ? "STAFF"
                        : formatTel(item.contact_tel)
                      : ""
                  }}
                </td>
                <td @click="clicked(item)">
                  <div
                    class="incident comments"
                    v-html="decodeContent(item.last_comment)"
                  ></div>
                </td>

                <td @click="clicked(item)">
                  {{
                    item.solvedate
                      ? moment(item.solvedate).format("DD-MM-YYYY HH:mm")
                      : ""
                  }}
                </td>
                <td @click="clicked(item)">
                  {{ item.attrib_user }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </div>
        </v-card-text>
      </template>
      <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
        transition="dialog-bottom-transition"
        content-class="ticket-dialog"
        :attach="'#' + tableId"
        :key="tableId"
        :loading="overlay"
      >
        <v-card>
          <v-toolbar v-if="isMobile" dark class="title" color="primary">
            {{ selectedTicket ? selectedTicket.name : "" }}
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dismissDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-toolbar v-else dark class="title" color="primary">
            Suivi d'intervention :
            {{ selectedTicket ? selectedTicket.name : "" }}
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dismissDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <ticket-detail
            v-if="selectedTicket"
            :ticket-id="selectedTicket.id"
            :ticket-name="selectedTicket.name"
            :ticket-date="selectedTicket.date"
            :overlay="overlay"
            @updateTicketStatusToPending="updateTicketStatusToPending"
          ></ticket-detail>
        </v-card>
      </v-dialog>
    </v-col>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
import {
  statusList,
  formatID,
  hideElt,
  formatTel,
  remove,
  headers,
  mobileHeaders,
  loadStates
} from "../../utils";
import { Decoder } from "../../mixins/decodeContent";
import TicketDetail from "./TicketDetail";
import moment from "../../plugins/moment";
import MobileRow from "../MobileRow";
import { mapGetters } from "vuex";
import { LocalMixin } from "../../mixins/local";
import { TiptapMixin } from "../../mixins/tiptap";
import TICKET_API from "../../api/ticket";
import USER_API from "../../api/user";
import { EventBus } from "../../event-bus";
import Swal from "sweetalert2";
import { mySwalClass } from "../../utils/alert";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "Incident",
  props: {
    tickets: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: String,
      default: "incidentTable"
    }
  },
  components: { TicketDetail, MobileRow, ToggleButton },
  data() {
    return {
      slider: true,
      list: [],
      search: "",
      expanded: [],
      groupement: "equipment",
      ticketsUsers: [],
      keyTab: 0,
      mobileHeaders: mobileHeaders(this.local),
      docFilterDialog: false,
      selectedTicket: undefined,
      dialog: false,
      //status: statusList(this.$root.$i18n.locale).map(stat => stat.name),  CCA: je ne veux pas les clos
      status: ["Nouveau", "Attribué", "En cours", "Planifié", "Résolu"],
      selectedStatus: ["Nouveau", "Attribué", "Planifié", "En cours"],
      categories: ["Permanent", "Exploitation"],
      selectedCategory: ["Exploitation"],
      types: ["1", "2"],
      loadStates,
      page: 1,
      pageCount: 0,
      snack: false,
      snackColor: "",
      snackText: "",
      selectedDegradations: [],
      overlay: false,
      classMod: "mt-n2"
    };
  },
  mixins: [Decoder, LocalMixin, TiptapMixin],
  mounted() {
    this.list = this.tickets;
    this.loadData().then(() => this.loadAttribUser());
    if (this.$route.query.status) {
      const str = this.$route.query.status;
      const routeStatus = str.charAt(0).toUpperCase() + str.slice(1);
      this.selectedStatus = [];
      this.selectedStatus.push(routeStatus);
    }
    if (this.$route.meta.restricted == true) {
      this.classMod = "mt-n2 d-none";
      this.search = this.getUsers.find(user => user.id === this.getUserId).name;
    }
    this.loadLastComment();
    EventBus.$on("closeSuiviDialog", this.dismissDialog);
  },
  destroyed() {
    // Destroy event listening
    EventBus.$off("closeSuiviDialog");
  },
  methods: {
    formatID,
    hideElt,
    formatTel,
    moment,
    async loadData() {
      this.ticketsUsers = (await USER_API.getTicketsUsers()).data;
    },
    deGroup() {
      this.slider = !this.slider;
      this.slider == true
        ? (this.groupement = "equipment")
        : (this.groupement = "");
    },
    getStatus(status) {
      return status
        ? statusList(this.$root.$i18n.locale).find(el => el.status === status)
        : { name: "", color: "" };
    },
    clicked: function(row) {
      this.selectedTicket = row;
      this.dialog = true;
      /*this.$router.push({
        name: "lots.ticket.show",
        params: { id: this.$route.params.id, ticketId: row.id }
      });*/
    },
    formatText(id) {
      if (id.date > localStorage.getItem("last2Refresh")) {
        return "<b>> " + formatID(id.id) + "</b>";
      } else return formatID(id.id);
    },
    formatDate(date) {
      let day = "";
      if (!date) return null;
      if (date.slice(0, 10) == moment().format("YYYY-MM-DD")) {
        day = "auj.";
      } else if (
        date.slice(0, 10) ==
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ) {
        day = "hier";
      } else day = moment(date).format("DD-MM");
      return day + " " + moment(date).format("HH:mm");
    },
    dismissDialog() {
      this.dialog = false;
      this.selectedTicket = undefined;
    },
    customSort(items, index, sortDesc) {
      // The following is informations as far as I researched.
      // items: 'tickets' items
      // index: Enabled sort headers value. (black arrow status).
      // sortDesc: Whether enabled sort headers is desc
      if (!index[1]) return items;

      items.sort((a, b) => {
        const attribute = index[1];
        let sortA = a[attribute];
        let sortB = b[attribute];
        const dateAttributes = [
          "date",
          "solvedate",
          "attributdate",
          "date_mod"
        ];
        if (dateAttributes.includes(attribute)) {
          if (!sortA) {
            sortA = "2010-01-01 00:00";
          }
          if (!sortB) {
            sortB = "2010-01-01 00:00";
          }
          const dateA = moment(sortA, "YYYY-MM-DD HH:mm:ss");
          const dateB = moment(sortB, "YYYY-MM-DD HH:mm:ss");

          if (sortDesc[0]) {
            if (dateB.isAfter(dateA)) {
              return 1;
            } else if (dateA.isAfter(dateB)) {
              return -1;
            } else {
              return 0;
            }
          } else {
            if (dateA.isAfter(dateB)) {
              return 1;
            } else if (dateB.isAfter(dateA)) {
              return -1;
            } else {
              return 0;
            }
          }
        }
      });

      return items;
    },
    expandRow(item) {
      this.expanded.push(item);
    },
    shoulExpand(id) {
      return this.expanded.includes(id);
    },
    removeFromExpanded(id) {
      this.expanded = remove(this.expanded, r => r.id === id);
    },
    titleText(selectedTicket) {
      try {
        const title = this.getActiveEntity.completename.replace(" ", "");
        const parentsEntities = title.split(">");
        let assetTitle = "";
        if (this.$vuetify.breakpoint.smAndDown) {
          assetTitle =
            parentsEntities[3].slice(0, 10) +
            " > " +
            parentsEntities[4].slice(0, 10);

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment.slice(0, 10);
        } else {
          assetTitle = parentsEntities[3] + " > " + parentsEntities[4];

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment;
        }
        return assetTitle;
      } catch (error) {
        return "";
      }
    },
    loadLastComment() {
      this.list.forEach(async ticket => {
        ticket.last_comment = "";

        const ticketID = ticket.id;
        const followUps = this.getAllITILFollowups.filter(
          fl => fl.items_id === ticketID
        );
        const tasks = this.getTicketsTasks.filter(
          fl => fl.tickets_id === ticketID
        );
        const solutions = this.getAllITILSolutions.filter(
          fl => fl.items_id === ticketID
        );

        let alldata = followUps.concat(tasks).concat(solutions);

        let sortedData = alldata.sort((a, b) => {
          if (moment(a.date_creation).isAfter(moment(b.date_creation))) {
            return -1;
          } else if (moment(b.date_creation).isAfter(moment(a.date_creation))) {
            return 1;
          } else {
            return 0;
          }
        });

        const lastComment = sortedData[0] ? sortedData[0].content : null;

        ticket.last_comment = lastComment;
      });
    },
    loadAttribUser() {
      this.list.forEach(async ticket => {
        ticket.attrib_user = "";
        let ticketUser = this.ticketsUsers.find(
          user => user.tickets_id === ticket.id
        );
        let ticketUserName = "";
        if (ticketUser) {
          ticketUserName = this.getUsers.find(
            user => user.id === ticketUser.users_id
          );
        }
        if (ticketUserName && ticketUserName.name)
          ticket.attrib_user = ticketUserName.name;
      });
      this.keyTab++;
    },
    saveName(item, value) {
      TICKET_API.changeName(
        item.id,
        item.itemtype,
        item.itemtypeID,
        value,
        item.contact_mail,
        item.contact_tel
      ).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data Updated";
      });
    },
    saveContent(item, value) {
      TICKET_API.changeDescription(item.id, value).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data Updated";
      });
    },
    saveType(item, value) {
      TICKET_API.changeType(item.id, value).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data Updated";
      });
    },
    popup() {
      Swal.fire({
        title: "Nouveau fonctionnement",
        text:
          "Pour modifier : cocher la/les ligne(s) aller dans le menu burger (=) en haut àgauche et passer à permanent, privé,...",
        icon: "info",
        timer: 5000,
        customClass: mySwalClass,
        timerProgressBar: true
      });
    },
    privateIncident(selectedDegradations, type) {
      let titre = "Vous voulez rendre " + selectedDegradations.length;
      type == 2
        ? (titre = titre + " intervention(s) privée(s)")
        : (titre = titre + " intervention(s) publique(s)");
      Swal.fire({
        title: "Êtes vous sûrs?",
        text: titre,
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          this.privateIncidentConfirmed(selectedDegradations, type);
        }
      });
    },
    async privateIncidentConfirmed(selectedDegradations, type) {
      for (const ticketId of selectedDegradations) {
        USER_API.getTicket(ticketId)
          .then(response => {
            if (response.data.type != type) {
              this.resolutionLoading = true;
              this.overlay = true;
              TICKET_API.changeType(ticketId, type)
                .then(() => {
                  EventBus.$emit("ticket-resolu", ticketId);
                  this.snack = true;
                  this.snackColor = "success";
                  this.snackText = "Data Updated";
                })
                .catch(({ response }) => {
                  this.snack = true;
                  this.snackColor = "error";
                  this.snackText = response.data[1];
                })
                .finally(() => {
                  this.resolutionLoading = false;
                  this.overlay = false;
                });
            }
          })
          .finally(() => {
            this.unselectAll();
          });
      }
    },
    permanentIncident(selectedDegradations) {
      //this.selectedCategory = ["Exploitation", "Permanent"];
      Swal.fire({
        title: "Êtes vous sûrs?",
        text:
          "Vous voulez rendre " +
          selectedDegradations.length +
          " intervention(s) permanente(s)",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          this.permanentIncidentConfirmed(selectedDegradations);
        }
      });
    },
    async permanentIncidentConfirmed(selectedDegradations) {
      for (const ticketId of selectedDegradations) {
        USER_API.getTicket(ticketId)
          .then(response => {
            if (response.data.itilcategories_id == 1) {
              this.resolutionLoading = true;
              this.overlay = true;
              this.saveCategories(response.data, "Permanent")
                .then(() => {
                  EventBus.$emit("ticket-resolu", ticketId);
                  this.snack = true;
                  this.snackColor = "success";
                  this.snackText = "Data Updated";
                })
                .catch(({ response }) => {
                  this.snack = true;
                  this.snackColor = "error";
                  this.snackText = response.data[1];
                });
            }
          })
          .finally(() => {
            this.resolutionLoading = false;
            this.overlay = false;
            this.unselectAll();
          });
      }
    },
    async saveCategories(item, value) {
      TICKET_API.changeCategories(item.id, value)
        .then(() => {
          TICKET_API.changeStatus(item.id, 4)
            .then(() => {
              EventBus.$emit("ticket-resolu", item.id);
              this.snack = true;
              this.snackColor = "success";
              this.snackText = "Data Updated";
            })
            .catch(({ response }) => {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = response.data[1];
            });
        })
        .finally(() => {
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Data Updated";
        });
    },
    cloreIncident(selectedDegradations) {
      Swal.fire({
        title: "Êtes vous sûrs?",
        text:
          "Vous voulez clore " +
          selectedDegradations.length +
          " intervention(s)",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          this.cloreIncidentConfirmed(selectedDegradations);
        }
      });
    },
    async cloreIncidentConfirmed(selectedDegradations) {
      for (const ticketId of selectedDegradations) {
        USER_API.getTicket(ticketId)
          .then(response => {
            if (response.data.status < 5) {
              let resolutionText = "-";
              this.resolutionLoading = true;
              this.overlay = true;
              TICKET_API.cloreIncident(
                ticketId,
                resolutionText,
                this.getActiveEntity.id
              )
                .then(() => {
                  EventBus.$emit("ticket-resolu", ticketId);
                  //this.selectedTicket = undefined;
                  this.snack = true;
                  this.snackColor = "success";
                  this.snackText = "Data Updated";
                })
                .catch(({ response }) => {
                  this.snack = true;
                  this.snackColor = "error";
                  this.snackText = response.data[1];
                })
                .finally(() => {
                  this.resolutionLoading = false;
                  this.overlay = false;
                });
            }
          })
          .finally(() => {
            this.unselectAll();
          });
      }
    },
    encoursIncident(selectedDegradations) {
      Swal.fire({
        title: "Êtes vous sûrs?",
        text:
          "Vous voulez passer " +
          selectedDegradations.length +
          " intervention(s) au statut 'en cours'",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          this.encoursIncidentConfirmed(selectedDegradations);
        }
      });
    },
    async encoursIncidentConfirmed(selectedDegradations) {
      for (const ticketId of selectedDegradations) {
        USER_API.getTicket(ticketId)
          .then(response => {
            if (response.data.status != 4) {
              this.resolutionLoading = true;
              this.overlay = true;
              TICKET_API.changeStatus(ticketId, 4)
                .then(() => {
                  EventBus.$emit("ticket-resolu", ticketId);
                  //this.selectedTicket = undefined;
                  this.snack = true;
                  this.snackColor = "success";
                  this.snackText = "Data Updated";
                })
                .catch(({ response }) => {
                  this.snack = true;
                  this.snackColor = "error";
                  this.snackText = response.data[1];
                })
                .finally(() => {
                  this.resolutionLoading = false;
                  this.overlay = false;
                });
            }
          })
          .finally(() => {
            this.unselectAll();
          });
      }
    },
    unselectAll() {
      this.selectedDegradations = [];
    },
    updateTicketStatusToPending() {
      const item = this.selectedTicket;
      this.overlay = true;
      if (item.status != 4) {
        TICKET_API.changeStatus(item.id, 4)
          .then(() => {
            EventBus.$emit("ticket-resolu", item.id);
            this.dialog = false;
            this.selectedTicket = undefined;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Data Updated";
          })
          .finally(() => {
            this.overlay = false;
          });
      }
    }
  },
  computed: {
    ...mapGetters([
      "getActiveEntity",
      "getAllITILSolutions",
      "getAllITILFollowups",
      "getTicketsTasks",
      "getTypeName",
      "getUsers",
      "getUserId"
    ]),
    searched() {
      this.unselectAll();
      if (this.search && this.search.includes(" ")) {
        let nbMots = 0;
        const space = / /g;
        let searches = [];
        while (space.exec(this.search)) {
          nbMots++;
        }
        for (let i = 0; i < nbMots; i++) {
          searches[i] = this.search.split(" ")[i];
        }
        if (this.search.split(" ")[nbMots]) {
          nbMots++;
          searches[nbMots - 1] = this.search.split(" ")[nbMots - 1];
        }
        if (nbMots == 2) {
          return searches[0] && searches[1];
        }
        if (nbMots == 3) {
          return searches[0] && searches[1] && searches[2];
        }
      }
      return this.search;
    },

    filteredItems() {
      this.unselectAll();
      return this.list.filter(i => {
        if (i.statusName !== "Clos") {
          if (i.itilcategories_id === 0) {
            i.itilcategories_id = "Non défini";
          }
          return (
            ((this.selectedStatus.length === 0 ||
              this.selectedStatus.includes(i.statusName)) &&
              (this.selectedCategory.length === 0 ||
                this.selectedCategory.includes(i.itilcategories_id))) ||
            (!i.id && i.equipment)
          );
        }
      });
    },
    headers() {
      const headersList = headers(this.local);
      headersList[7].value = "contact_mail";

      return headersList;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 10 : 30;
    },
    filteredItemsName() {
      return this.filteredItems.filter(el => !!el.name).map(item => item.name);
    }
  },
  watch: {
    $route() {
      this.list = this.tickets;
      this.loadLastComment();
      this.loadAttribUser();
    },
    tickets() {
      this.list = this.tickets;
      this.loadLastComment();
      this.loadAttribUser();
    }
  }
};
</script>
<style>
/* Modifier la valeur de max-height pour fixer la hauteur des lignes du tableau */

:root {
  --max-height: 100px;
  --desc-max-width: 350px;
  --content-max-width: 200px;
}

tr:nth-of-type(even) {
  background-color: #f5f5f5;
}

.t-row {
  color: #2d2d2d;
}
.table.is-bordered tr:last-child th {
  border-bottom-width: 1px !important;
}
.table.is-bordered th {
  border-width: 1px !important;
  color: #363636 !important;
}
@media only screen and (max-width: 799px) and (min-width: 240px) {
  .table th {
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
  }
}
@media only screen and (max-width: 2800px) and (min-width: 800px) {
  .table th {
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
.v-data-table table td {
  border-width: 1px !important;
  border: 1px solid #dbdbdb !important;
  vertical-align: middle !important;
}
.incident p {
  margin-bottom: 0 !important;
}
.resolu {
  color: white !important;
}
.description {
  min-width: 180px;
  max-width: var(--desc-max-width);
  max-height: var(--max-height);
  margin-left: 0 !important;
  margin-right: 0 !important;
  overflow: auto;
}
.equipment {
  max-width: 10px;
}
.comments {
  max-width: var(--content-max-width);
  max-height: var(--max-height);
  overflow: auto;
}
</style>
