<template>
  <ticket filtered>
    <template v-slot="slotProps">
      <tab-onglet
        v-if="
          Object.keys(slotProps.exposedData.tickets_items).length > 1 && isTab
        "
        :exposed-data="slotProps.exposedData"
        >{{ getFirstElement(slotProps.exposedData.tickets_items) }}
      </tab-onglet>
      <v-row align="start" justify="center" class="fill-height mx-0" v-else>
        <v-col cols="12" class="px-0 px-md-0">
          <incident
            :loaded="slotProps.exposedData.loaded"
            :loading="slotProps.exposedData.loading"
            :tickets="getFirstElement(slotProps.exposedData.tickets_items)"
          ></incident>
        </v-col>
        <v-card-title class="headline display-1" v-if="!$route.meta.fromEcran">
          PERFORMANCES DU SITE
        </v-card-title>
        <v-col cols="12">
          <tickets-chart
            :tickets="getFirstElement(slotProps.exposedData.tickets_items)"
            :loaded="slotProps.exposedData.loaded"
            :search-key="getSearchKey(slotProps.exposedData.tickets_items)"
            :equip-label="getTypeName(slotProps.exposedData.tickets_items)"
            v-if="!$route.meta.fromEcran"
          ></tickets-chart>
        </v-col>
      </v-row>

      <v-row align="start" justify="center" class="fill-height mx-0">
        <v-col cols="12" class="px-0 px-md-0">
          <env-performance
            :entity-id="Number.parseInt($route.params.id)"
            v-if="!$route.meta.fromEcran"
          ></env-performance>
        </v-col>
      </v-row>
    </template>
  </ticket>
</template>
<script>
import Incident from "./Incident";
import TicketsChart from "./TicketsChart";
import EnvPerformance from "../ecran/EnvPerformance";
import Ticket from "./Ticket";
import TabOnglet from "./TabOnglet";
import { mapGetters } from "vuex";

export default {
  name: "Onglet",
  components: { TicketsChart, Incident, Ticket, EnvPerformance, TabOnglet },
  computed: {
    ...mapGetters(["getActiveEntity", "getUserProfiles"]),
    isTab() {
      return this.getUserProfiles.includes(34); //profil kit_multi gérant plusieurs objets
    }
  },
  methods: {
    getFirstElement(ticketsItems) {
      const keys = Object.keys(ticketsItems);
      if (keys.length > 0) {
        return ticketsItems[keys[0]];
      }
      return [];
    },
    getSearchKey(ticketsItems) {
      const keys = Object.keys(ticketsItems);
      if (keys.length > 0) {
        return keys[0].toLowerCase().startsWith("plugingenericobject")
          ? "equipment"
          : "name";
      }
      return "name";
    },
    getTypeName(ticketsItems) {
      const keys = Object.keys(ticketsItems);
      const type = keys.length > 0 ? keys[0] : "location";
      let states = this.getActiveEntity.state;
      states = states ? JSON.parse(states) : states;
      let typeName = states && states[type] ? states[type] : null;
      if (type === "location" && !typeName) {
        return "Emplacement";
      }
      return typeName ? typeName : type;
    }
  }
};
</script>
